import "@fontsource/noto-sans-jp"

import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"

import { navigate } from "gatsby"

export const onRouteUpdate = ({ location }) => {
if (location.pathname === "/tech-academia_kids/") {
navigate("/", { replace: true }); // navigate を使ってリダイレクト
}
};